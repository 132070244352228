import React from 'react'
import Image from 'gatsby-image';
import { graphql, useStaticQuery} from 'gatsby'


const Hero = () => {
  const {image} = useStaticQuery(graphql`
    query {
      image: file(relativePath: {eq: "title3.png"}){
        sharp: childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <Image className="hero-bg" fluid={image.sharp.fluid} fadeIn="soft" style={{'heigth': '80%' }}/>
    
     
  )
}
export default Hero