import React from "react"
import Content from "../../components/content"
import Hero from "../../components/hero"

export default function Uusiyritys() {
  return (
    <section>
      <Content>
      <h1>Uusi yritys</h1>
      <Hero></Hero>
      <p style={{'fontSize':'0.7em'}}>Jyväskylän koronaluvut ovat alkaneet näyttää sen verran huolestuttavilta, että isoja juhlia ei ole mielekästä järjestää. Siksi joudumme nyt perumaan häät.</p>

<p style={{'fontSize':'0.7em'}}>Koska ehdimme jo hoitaa juhlien virallisen puolen maistraatissa kuntoon, ei ensi kesänä kannata enää häitä juhlia ihan samassa muodossa. Olemmehan silloin olleet naimisissa jo lähes vuoden. Pidämme kuitenkin juhlista ja teistä, joten jonkinlaiset jälkihäät / vuosipäiväjuhlat kuitenkin pidetään. Se on varma! </p>

<p style={{'fontSize':'0.7em'}}>Merkitse jo nyt kalenteriisi päivämäärä <b>28.5.2022</b>. Aikataulu ja ohjelma tarkentuvat lähempänä. Päivitämme tarkemmat tiedot nettisivuillemme. Pääset kirjautumaan sivulle kutsukortissa olevilla tunnuksilla.</p>

<p style={{'fontSize':'0.7em'}}>Jospa toukokuussa sitten nähtäisiin? </p>
<div style={{'textAlign':'center'}}><span role="img" alt="heart">❤️</span></div>
 <p style={{'fontSize':'0.7em'}}>Annakaisa ja Markku eli Lehtoset </p>


      </Content>
    </section>
  )
}
